import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripLines,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


export default function NavigationEditor({ data, setData, websiteReference }) {
  const deleteBranch = (branch) => {
    if ((!branch.text && !branch.href) || window.confirm(`Really delete?`)) {
      removeBranch(branch.id);
    }
  };

  const deleteLeaf = (leaf) => {
    if ((!leaf.text && !leaf.href) || window.confirm(`Really delete?`)) {
      removeLeaf(leaf.id);
    }
  };

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    let newBranches;
    if (result.type === "droppableBranch") {
      newBranches = reorder(data, sourceIndex, destIndex);
    } else if (result.type === "leaf") {
      const branchLeafMap = data.reduce((branches, branch, index) => {
        branches[index] = branch.children;
        return branches;
      }, {});

      const sourceParentId = parseInt(result.source.droppableId);
      const destParentId = parseInt(result.destination.droppableId);

      const sourceLeaves = branchLeafMap[sourceParentId];
      const destLeaves = branchLeafMap[destParentId];

      newBranches = [...data];

      // In this case items are reordered inside same parent
      if (sourceParentId === destParentId) {
        const reorderedLeaves = reorder(
          sourceLeaves,
          sourceIndex,
          destIndex
        );
        newBranches = newBranches.map((branch, index) => {
          if (index === sourceParentId) {
            branch.children = reorderedLeaves;
          }
          return branch;
        });
      } else {
        let newSourceLeaves = [...sourceLeaves];
        const [draggedBranch] = newSourceLeaves.splice(sourceIndex, 1);

        let newDestLeaves = [...destLeaves];
        newDestLeaves.splice(destIndex, 0, draggedBranch);
        newBranches = newBranches.map((branch, index) => {
          if (index === sourceParentId) {
            branch.children = newSourceLeaves;
          } else if (index === destParentId) {
            branch.children = newDestLeaves;
          }
          return branch;
        });
      }
    }
    setData(newBranches);
  };

  const onFieldChange = (id, key, value) => {
    const branches = [...data];

    branches.forEach(branch => {
      if(branch.id === id) {
        branch[key] = value;
      };
      branch.children.forEach(leaf => {
        if(leaf.id === id) {
          leaf[key] = value;
        }
      });
    });
    setData(branches);
  };

  const addLeaf = (branchId) => {
    const branches = [...data];

    branches.forEach(branch => {
      if(branch.id === branchId) {
        if(!branch.children.length) {
          branch.children.push({
            id: `${branchId}_0`,
            text: '',
            href: '',
          });
        } else {
          for(let count = 0; true; count++) {
            if(!branch.children.some(l => l.id === `${branchId}_${count}`)) {
              branch.children.push({
                id: `${branchId}_${count}`,
                text: '',
                href: '',
              });
              break;
            }
          }
        }
      }
    });

    setData(branches);
  };

  const removeLeaf = (id) => {
    const branches = [...data];

    branches.forEach((branch, branchIndex) => {
      branch.children.forEach((leaf, index) => {
        if(leaf.id === id) {
          branches[branchIndex].children.splice(index, 1);
        }
      });
    });
    setData(branches);
  };

  const addBranch = () => {
    const branches = [...data];

    for(let count = 0; true; count++) {
      if(!branches.some(b => b.id === `${count}`)) {
        branches.push({
          id: `${count}`,
          text: '',
          href: '',
          children: [],
        });
        break;
      }
    }

    setData(branches);
  };

  const removeBranch = (id) => {
    const branches = [...data];
    branches.forEach((branch, index) => {
      if(branch.id === id) {
        branches.splice(index, 1);
      }
    });
    setData(branches);
  };


  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="droppableBranch">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {data.map((branch, branchIndex) => (
                <Draggable key={`${branchIndex}`} draggableId={`${branchIndex}`} index={branchIndex}>
                  {(provided, snapshot) => (
                    <>
                      <div ref={provided.innerRef} {...provided.draggableProps}>

                        <nav className="level pt-4 mb-2">
                          <div className="level-left">
                            <div className="level-item" {...provided.dragHandleProps}>
                              <span className="icon">
                                <FontAwesomeIcon icon={faGripLines} />
                              </span>
                            </div>
                            <div className="level-item">
                              <div className="field">
                                <div className="control">
                                  <input className="input"
                                         type="text"
                                         placeholder="Title"
                                         value={branch.text || ''}
                                         onChange={e => onFieldChange(branch.id, 'text', e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="level-item">
                              <div className="field">
                                <div className="control">
                                  <input className="input"
                                         type="text"
                                         placeholder="https://example.com"
                                         size="30"
                                         value={branch.href || ''}
                                         onChange={e => onFieldChange(branch.id, 'href', e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="level-right">
                            <button onClick={() => deleteBranch(branch)} className="button card-footer-item" disabled={branch.children.length}>Delete</button>
                          </div>
                        </nav>

                        <Droppable droppableId={`${branchIndex}`} type="leaf" direction="vertical">
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef}>

                              {branch.children.map((leaf, index) => (
                                <Draggable key={leaf.id} draggableId={leaf.id} index={index}>
                                  {(provided, snapshot) => (
                                    <>
                                      <div ref={provided.innerRef} {...provided.draggableProps}>

                                        <nav className="level pb-2 pl-4">
                                          <div className="level-left">
                                            <div className="level-item" {...provided.dragHandleProps}>
                                              <span className="icon">
                                                <FontAwesomeIcon icon={faGripLines} />
                                              </span>
                                            </div>
                                            <div className="level-item">
                                              <div className="field">
                                                <div className="control">
                                                  <input className="input"
                                                         type="text"
                                                         placeholder="Title"
                                                         value={leaf.text || ''}
                                                         onChange={e => onFieldChange(leaf.id, 'text', e.target.value)}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="level-item">
                                              <div className="field">
                                                <div className="control">
                                                  <input className="input"
                                                         type="text"
                                                         placeholder="https://example.com"
                                                         size="30"
                                                         value={leaf.href || ''}
                                                         onChange={e => onFieldChange(leaf.id, 'href', e.target.value)}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="level-right">
                                            <button onClick={() => deleteLeaf(leaf)} className="button card-footer-item">Delete</button>
                                          </div>
                                        </nav>

                                      </div>

                                      {provided.placeholder}
                                    </>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}

                              <nav className="level pb-2 pl-4">
                                <div className="level-left">
                                  <div className="level-item">
                                  </div>
                                  <div className="level-item pl-4">
                                    <button className="button is-ghost pt-2" onClick={() => addLeaf(branch.id)}>
                                      <span className="icon">
                                        <FontAwesomeIcon icon={faPlus} />
                                      </span>
                                      <span>Add subitem</span>
                                    </button>
                                  </div>
                                </div>
                              </nav>

                            </div>
                          )}
                        </Droppable>

                      </div>
                    {provided.placeholder}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}

              <div className="columns mt-4">
                <div className="column has-text-centered add-row">
                  <button className="button is-ghost"
                          onClick={addBranch}>
                    <span className="icon is-size-5">
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                    <span>Add item</span>
                  </button>
                </div>
              </div>

            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}


NavigationEditor.propTypes = {
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
