export default function VimeoModule({ token, logout, data, onFieldChange, websiteReference }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label" htmlFor={'url' + id}>Embed link</label>
        <div className="control">
          <input id={'url' + id}
                 className="input"
                 type="url"
                 placeholder="https://player.vimeo.com/video/76979871?background=1"
                 value={data.url}
                 onChange={e => {onFieldChange(data.id, 'url', e.target.value)}}
          />
        </div>
        <p className="help">Embed link from Vimeo</p>
      </div>

      <div className="field">
        <label className="label" htmlFor={'width' + id}>Width</label>
        <div className="control">
          <input id={'width' + id}
                 className="input"
                 type="number"
                 placeholder=""
                 value={data.width}
                 onChange={e => {onFieldChange(data.id, 'width', e.target.value)}}
          />
          <p className="help">Width in pixels</p>
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'height' + id}>Height</label>
        <div className="control">
          <input id={'height' + id}
                 className="input"
                 type="number"
                 placeholder=""
                 value={data.height}
                 onChange={e => {onFieldChange(data.id, 'height', e.target.value)}}
          />
          <p className="help">Height in pixels</p>
        </div>
      </div>
    </>
  );
}
