import { useState } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

import changing     from '../../images/changing-pages.svg';
import { BASE_URL } from '../api';


async function login(credentials) {
  Object.keys(credentials).forEach(key => credentials[key] || delete credentials[key]);
  return fetch(`${BASE_URL}/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials),
  });
}


export default function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorText, setErrorText] = useState();

  const handleSubmit = e => {
    e.preventDefault();

    login({email, password})
      .then(response => response.json())
      .then(response => {
        if(response.status === 'success') {
          setToken({'token': response.token});
        } else {
          setErrorText(response.message || 'An unknown error occurred, please contact suppport');
        }
      });
  }

  return(
    <div className="columns">
      <div className="column">
        <div className="box form-box animate pop delay-1">
          <p><img className="is-pulled-right pt-1" src={changing} alt=""></img></p>

          <h1 className="title mb-5">Spearmint CMS</h1>

          <form onSubmit={handleSubmit} className="pt-2">
            <div className="field">
              <p className="control has-icons-left">
                <input className="input"
                       type="email"
                       autoComplete="username"
                       id="email"
                       name="email"
                       placeholder="Email"
                       required
                       onChange={e => setEmail(e.target.value)} />
                <span className="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input className="input"
                       type="password"
                       autoComplete="current-password"
                       id="current-password"
                       placeholder="Password"
                       required
                       onChange={e => setPassword(e.target.value)} />
                <span className="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </p>
            </div>
            <div className="field mt-5">
              <span className="control">
                <button className="button is-primary" type="submit">Login</button>
              </span>
              <span className="is-pulled-right mt-1">
                <Link to="/password/reset" className="is-link">Forgot password</Link>
              </span>
            </div>
            {errorText && (
              <span className="tag is-danger">{errorText}</span>
            )}
          </form>
        </div>       
      </div>
    </div>
  );
}


Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
