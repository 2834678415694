import { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router';

import api           from '../api';
import PageEditor    from '../page-editor/page-editor';
import PublishButton from '../publish-button/publish-button';
import SavedStatus   from '../saved-status/saved-status';


// Add unique id for each column in data.rows
const addColumnIds = (data) => {
  if(!data.rows) return {...data, rows: []};

  data.rows.forEach((row, rowIndex) => {
    row.id = `${rowIndex}`;
    row.columns.forEach((column, index) => column.id = `${rowIndex}_${index}`);
  });

  return data;
};


export default function Page({ token, logout }) {
  const {reference, '*': path} = useParams();
  const [pageData, setPageData] = useState({ rows: [] });
  const [unpublished, setUnpublished] = useState(false);
  const [deploying, setDeploying] = useState('');
  const [saved, setSaved] = useState(true);
  const dataEndpoint = `/websites/${reference}/data/pages/${path}`;
  const metadataEndpoint = `/websites/${reference}/metadata/pages/${path}`;
  const deployEndpoint = `/websites/${reference}/deploy`;
  const autosave = useRef(null);

  // Save the internal page data and occasionally send it to the API
  const savePageData = data => {
    setPageData(data);
    setSaved(false);

    if(autosave.current) clearTimeout(autosave.current);
    autosave.current = setTimeout(() => {
      autosave.current = null;
      api(`${dataEndpoint}?unpublished=1`, token, logout, {
        method: 'PUT',
        body: JSON.stringify(data),
      })
        .then(() => {
          setSaved(true);
          api(metadataEndpoint, token, logout)
            .then(response => setUnpublished(response.unpublished_changes));
        });
    }, 1000);
  };

  useEffect(() => {
    api(`${dataEndpoint}?unpublished=1`, token, logout)
      .then(response => {
        if(response.error) response = {};
        setPageData(addColumnIds(response));
      });
  }, [token, logout, dataEndpoint]);

  // Deploy unpublished changes to the website
  const publishChanges = () => {
    setDeploying('deploying');
    api(dataEndpoint, token, logout, {
      method: 'PUT',
      body: JSON.stringify(pageData),
    })
      .then(() => {
        api(deployEndpoint, '', logout, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        })
          .then(response => {
            setDeploying(response.status);
          });
      });
  };

  // Deploy status
  useEffect(() => {
    const getStatus = () => {
      api(deployEndpoint, token, logout)
        .then(response => setDeploying(response.status));
    };
    if(deploying && deploying !== 'deploying') return;
    const interval = setInterval(getStatus, 1000);
    return () => clearInterval(interval);
  }, [token, logout, deployEndpoint, deploying]);

  // Unpublished status
  useEffect(() => {
    api(metadataEndpoint, token, logout)
      .then(response => setUnpublished(response.unpublished_changes));
  }, [token, logout, metadataEndpoint, deploying]);

  return (
    <div className="container">
      <nav className="breadcrumb mb-6 is-large" aria-label="breadcrumbs">
        <ul>
          <li key="pages">
            <Link to={`/websites/${reference}/pages`} className="pr-3">Pages</Link>
          </li>
          <>
            {path.split('/').map((node, index) => (
              <li key={index}>
                <span className="pl-3 pr-3">{node}</span>
              </li>
            ))}
          </>
        </ul>
      </nav>

      <div>
        <nav className="level">
          <div className="level-left">
            <PublishButton deploy={publishChanges}
                           deploying={deploying}
                           unpublished={unpublished}
                           saved={saved} />
          </div>
          <div className="level-right">
            <div className="level-item">
              <SavedStatus saved={saved} />
            </div>
          </div>
        </nav>

        <PageEditor token={token}
                    logout={logout}
                    pageData={pageData}
                    setPageData={savePageData}
                    websiteReference={reference} />
      </div>
    </div>
  );
}


Page.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};
