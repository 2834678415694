import PropTypes from 'prop-types';
import Switch from '../switch/switch';

import PageImage from './page-image';


export default function PageMetadata({ token, logout, data, setData, websiteReference }) {
  return (
    <>
      <div className="field">
        <Switch
          value={data.enabled}
          setValue={() => {
            data.enabled = !data.enabled;
            setData(data);
          }}
          id="something"
          label="Public"
          title="Disabling this will hide the page, resulting in a 404"
        />
        <p className="help">Should this page appear on the public website?</p>
      </div>

      <div className="field">
        <label className="label" htmlFor="title">Page title</label>
        <div className="control">
          <input id="title"
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.title || ''}
                 onChange={e => setData({...data, title: e.target.value})}
          />
        </div>
        <p className="help">Short title that appears in search results and social media</p>
      </div>

      <details>
        <summary className="clickable">More page metadata</summary>

        <div className="field mt-4">
          <label className="label" htmlFor="description">Page description</label>
          <div className="control">
            <input id="description"
                   className="input"
                   type="text"
                   placeholder=""
                   value={data.description || ''}
                   onChange={e => setData({...data, description: e.target.value})}
            />
          </div>
          <p className="help">Page description that appears in search results and social media posts</p>
        </div>

        <div className="field">
          <label className="label" htmlFor="image">Social media image</label>
          <PageImage token={token}
                     logout={logout}
                     data={data}
                     setData={setData}
                     websiteReference={websiteReference} />
          <p className="help">Image displayed on social media, for best results this should be around 1200 pixels wide and  630 pixels tall</p>
        </div>

        <div className="field mt-4">
          <label className="label" htmlFor="robots"><code>robots</code> meta tag</label>
          <div className="control">
            <input id="robots"
                   className="input"
                   type="text"
                   placeholder=""
                   value={data.robots || ''}
                   onChange={e => setData({...data, robots: e.target.value})}
            />
          </div>
          <p className="help">Set the content of the <code>robots</code> meta tag, a value of "noindex" will stop this page appearing in search results</p>
        </div>
      </details>
    </>
  );
}


PageMetadata.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
