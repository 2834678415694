import { useState, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faFileAlt,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons';

import api from '../api';


export default function PageList({ token, logout }) {
  const [pages, setPages] = useState([]);

  const { reference } = useParams();

  useEffect(() => {
    api(`/websites/${reference}/data`, token, logout)
      .then(response => {
        let pages = Object.values(response);

        // Only get data under pages/
        pages = pages.filter(page => page.path.startsWith('pages/'));
        pages.forEach((page, index) => pages[index].path = page.path.replace(/^pages\//, ''));

        // Ignore station pages that are managed in the GCRP station CMS
        pages = pages.filter(page => page.layout !== 'map-page');

        pages = pages.sort((a, b) => a.path.localeCompare(b.path));

        // Move index to the top
        pages.forEach((_, i) => pages[i].path === 'index' && pages.unshift(pages.splice(i, 1)[0]));

        setPages(pages);
      });
  }, [token, logout, reference]);

  return (
    <div className="container">
      <h2 className="title is-2 mb-6">
        <span className="icon is-small is-right has-text-primary">
          <FontAwesomeIcon icon={faList} />
        </span>
        All pages
      </h2>
      <div className="box">
        <ul className="page-site-list">
          <>
            {pages.map(page => (
              <li key={page.path}>
                <span className="icon is-small is-right mr-3 has-text-primary">
                  <FontAwesomeIcon icon={faFileAlt} />
                </span>
                <Link to={`/websites/${reference}/pages/${page.path}`}>{page.path}{page.path === 'index' && ' (home)'}</Link>
                {page.unpublished_changes && (
                  <>
                   <span className="is-hidden-touch is-pulled-right tag is-warning">Unpublished changes</span>
                    <span data-tooltip="Unpublished" className="tooltip is-hidden-desktop tag is-pulled-right is-warning">
                      <FontAwesomeIcon icon={faXmarkCircle} />
                    </span>
                  </>
                )}
              </li>
            ))}
          </>
        </ul>
      </div>
    </div>
  );
}


PageList.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};
