import { BrowserRouter, Route, Routes } from 'react-router';
import './App.scss';

import useToken       from './components/login/use-token';
import api            from './components/api';
import Navbar         from './components/navbar/navbar';
import Footer         from './components/footer/footer';
import Login          from './components/login/login';
import PasswordReset  from './components/password/reset';
import PasswordChange from './components/password/change';
import WebsiteList    from './components/websites/website-list';
import Page           from './components/pages/page';
import PageList       from './components/pages/page-list';
import GlobalContent  from './components/global-content/global-content';
import FooterContent  from './components/footer-content/footer-content';
import Navigation  from './components/navigation/navigation';


export default function App() {
  const { token, setToken } = useToken();

  function logout() {
    const oldToken = token;
    setToken('');

    api('/authenticate/logout', '', () => {}, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${oldToken}`,
        'Content-Length': '0',
      },
    });
  }

  return (
    <BrowserRouter>

      {/* Navigation bar */}
      <Routes>
        <Route path="/websites/:reference/*"
               element={<Navbar token={token} logout={logout} active="pages" />}
        />
        <Route path="/websites/:reference/global"
               element={<Navbar token={token} logout={logout} active="global" />}
        />
        <Route path="/websites/:reference/footer"
               element={<Navbar token={token} logout={logout} active="footer" />}
        />
        <Route path="/websites/:reference/navigation"
               element={<Navbar token={token} logout={logout} active="navigation" />}
        />
        <Route path="/*" element={<Navbar token={token} logout={logout} />} />
      </Routes>

      <section className="section section-cms animate pop delay-1">
        <Routes>
          {/* Default to website list */}
          <Route index element={token ? <WebsiteList token={token} logout={logout} /> : <Login setToken={setToken} /> } />

          {/* Password change flow */}
          <Route path="/password/reset" element={<PasswordReset />} />
          <Route path="/password/change/:token" element={<PasswordChange logout={logout} />} />

          <Route path="/websites/:reference/"
                 element={token ? <PageList token={token} logout={logout} /> : <Login setToken={setToken} />}
          />
          <Route path="/websites/:reference/pages"
                 element={token ? <PageList token={token} logout={logout} /> : <Login setToken={setToken} />}
          />
          {/* What to do about this? */}
          <Route path="websites/:reference/pages/*"
                 element={token ? <Page token={token} logout={logout} /> : <Login setToken={setToken} />}
          />
          <Route path="/websites/:reference/global"
                 element={token ? <GlobalContent token={token} logout={logout} /> : <Login setToken={setToken} />}
          />
          <Route path="/websites/:reference/footer"
                 element={token ? <FooterContent token={token} logout={logout} /> : <Login setToken={setToken} />}
          />
          <Route path="/websites/:reference/navigation"
                 element={token ? <Navigation token={token} logout={logout} /> : <Login setToken={setToken} />}
          />
        </Routes>
      </section>

      <Footer />
    </BrowserRouter>
  );
}
